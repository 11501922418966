.image-slider {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
  margin: auto;
}

.image-slider img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Add border radius for a rounded appearance */
  object-fit: contain;
}

.slider-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-navigation img {
  width: 50px; /* Adjust the width of the navigation images */
  height: auto;
  margin: 0 5px;
  border: 1px solid #ddd; /* Add a border for better visibility */
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.slider-navigation img.active {
  opacity: 0.7; /* Adjust the opacity for the active navigation image */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .image-slider {
    width: 100%;
    max-width: none; /* Remove max-width for smaller screens */
  }

  .image-slider img {
    border-radius: 0; /* Adjust border radius for smaller screens if needed */
  }

  .slider-navigation img {
    width: 50px; /* Adjust the width of the navigation images for smaller screens */
  }
}
