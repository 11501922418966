/* styles.css */
@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.fade-in {
  opacity: 0.1;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}
