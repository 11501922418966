.brands-section {
  padding: 60px 0;
  background-color: #f8f8f8;
}

.brands-slider {
  margin-top: 40px;
}

.brand-item {
  padding: 10px;
}

.brand-card {
  height: 100px !important;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.brand-card:hover {
  transform: translateY(-5px);
}

.brand-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.brand-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px !important;
  object-fit: contain;
  padding: 20px;
}

/* Custom arrow styles */
.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #f0f0f0;
}

.slick-prev {
  left: -20px;
}

.slick-next {
  right: -20px;
}

.slick-prev:before,
.slick-next:before {
  color: #333;
  font-size: 20px;
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
