.top-categories-section {
  padding: 60px 0;
  overflow: hidden;
}

.category-item {
  padding: 10px;
}

.category-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px);
}

.category-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.category-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-name {
  padding: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* Custom arrow styles */
.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .slick-prev {
    left: -30px;
  }
  .slick-next {
    right: -30px;
  }
}

@media (max-width: 576px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}
